<template>
  <div>
    <div class="flex-between">
      <div class="title">{{ $t("enterpriseRegister") }}</div>
      <!-- <el-button
        class="btn-black"
        @click="bindEnterprise = true, errorCode = false"
      >{{ $t("bindingCoreEnterprises") }}</el-button>-->
    </div>
    <el-table :data="tableData" style="width: 1200px">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column prop="username" :label="$t('brandOwnerName')" align="center">
        <template v-slot="scope">{{ scope.row.boUser && scope.row.boUser.username }}</template>
      </el-table-column>
      <el-table-column
        prop="address"
        :label="$t('businessID')"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.boUser && scope.row.boUser.address }}</template>
      </el-table-column>
      <el-table-column prop="reOrders" :label="$t('purchaseOrders')" align="center">
        <template v-slot="scope">{{ scope.row.boUser && scope.row.boUser.reOrders }}</template>
      </el-table-column>
      <el-table-column prop="fnOrders" :label="$t('applyForFinancingOrders')" align="center">
        <template v-slot="scope">{{ scope.row.boUser && scope.row.boUser.fnOrders }}</template>
      </el-table-column>
      <el-table-column
        prop="fnOrdersAmount"
        :label="$t('applyForFinancingOrdersAmount')"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.boUser && scope.row.boUser.fnOrdersAmount }}</template>
      </el-table-column>
    </el-table>
    <!-- <el-dialog :title="$t('dialogMsg.title')" :visible.sync="bindEnterprise">
      <div class="flex-base invitation-code">
        <h1 v-if="language === 'zh-cn'">{{ $t("tipsMessage.invitationCode") }}</h1>
        <h1 v-else>
          {{ $t("tipsMessage.invitationCode").split("you")[0] + "you" }}
          <br />
          {{ $t("tipsMessage.invitationCode").split("you")[1] }}
        </h1>
        <div class="flex-row-center captcha_input_wrapper">
          <el-input
            v-for="(item, index) in inputCode"
            :key="index"
            v-model="item.code"
            :id="'testPaste' + index"
            class="captcha_input_box row-center"
            @input="handleInput(index)"
            @keydown.native="keyDown(index)"
            @paste.native="handlePaste()"
          >></el-input>
        </div>
        <el-button
          class="btn-black btn-submit"
          @click="submitInvitation"
          :disabled="isSubmit"
        >{{ $t("submitSure") }}</el-button>
        <div
          class="text-error"
          v-show="errorCode"
        >{{ $t("tipsMessage.invitationCodeError1") }} {{ $t("tipsMessage.invitationCodeError2") }}</div>
      </div>
    </el-dialog>-->
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import AdPagination from "@/components/ad-pagination";
export default {
  components: {
    AdPagination
  },
  data() {
    return {
      // 当前输入框
      keyCode: 0,
      pasteFlag: false,
      inputCode: [{
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }, {
        code: ""
      }],
      isSubmit: true,
      bindEnterprise: false,
      errorCode: false,
      form: {
        name: ''
      },
      tableData: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      btnList: [{ title: 'purchaser', select: true }, { title: 'supplier', select: false }],
      financeMap: [],
      tableLoading: false,
      tableEmpty: this.$t('loading')
    };
  },
  created() {
    this.getBrandOwnerList();
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getBrandOwnerList();
    },
    getBrandOwnerList() {
      this.tableLoading = true;
      const data = {
        page: this.tablePageCP,
        pageSize: this.tablePagePS
      };
      this.$axios.get('/v1/supplier/brandowners', { params: data }).then((response) => {
        if (response.code === 0 || response.code === '0') {
          this.tableData = response.data.rows || [];
          this.tableTotal = response.data.count || 0;
          this.tableLoading = false;
          this.tableEmpty = this.$t('tableEmpty');
          this.tableData.map((item) => {
            item.boUser.fnOrdersAmount = item.boUser && item.boUser.fnOrdersAmount ? Number(item.boUser.fnOrdersAmount) / this.$enums.UNIT_MILLION : '0';
          });
        } else {
          this.tableLoading = false;
          this.tableEmpty = this.$t('tableEmpty');
          this.$message({ type: 'error', message: response.message });
        }
      }).catch(error => {
        this.tableLoading = false;
        this.tableEmpty = this.$t('tableEmpty');
      });
    }
    // handleDelete(index, rows) {
    //   rows.splice(index, 1);
    // },
    // typeClick(item, index) {
    //   this.btnList.forEach(item => {
    //     item.select = false;
    //   });
    //   this.btnList[index].select = true;
    // },
    // submitInvitation() {
    //   this.isSubmit = true;
    //   let code = this.inputCode.map((value) => {
    //     return value.code;
    //   });
    //   if (code.join('').length !== 8) {
    //     return false;
    //   }
    //   this.$axios.post('/loan/user/register/code', {
    //     code: code.join('')
    //   }).then(result => {
    //     console.log(result);
    //     this.isSubmit = false;
    //     this.bindEnterprise = false;
    //     this.getBrandOwnerList();
    //   }).catch(err => {
    //     if (err.toString() === "2002") {
    //       this.isSubmit = false;
    //       this.errorCode = true;
    //     }
    //     this.isSubmit = false;
    //     this.getBrandOwnerList();
    //     console.log("register code error {}", err);
    //   });
    // },
    // handleInput(index) {
    //   index = index < this.inputCode.length - 1 ? index : this.inputCode.length - 1;
    //   let nextIndex = index < this.inputCode.length - 1 ? index + 1 : this.inputCode.length - 1;
    //   if (this.keyCode === 8) {
    //     nextIndex = index;
    //     let dom = document.getElementById("testPaste" + (nextIndex - 1));
    //     if (dom) dom.focus();
    //     this.$set(this.inputCode[index], "code", "");
    //     return false;
    //   }
    //   // 粘贴的值 具体的处理方式
    //   if (this.pasteFlag) {
    //     if (this.inputCode[index].code.length > 1) {
    //       let codes = this.inputCode[index].code.replace(/\s*/g, "").toUpperCase();
    //       let codeArr = codes.split("");
    //       for (let i in this.inputCode) {
    //         this.$set(this.inputCode[i], "code", codeArr[i]);
    //       }
    //       let lashIndex = codes.length >= 8 ? 7 : codes.length;
    //       let dom = document.getElementById("testPaste" + lashIndex);
    //       if (dom) dom.focus();
    //     }
    //     this.pasteFlag = false;
    //   } else {
    //     if (this.inputCode[index].code.length > 1) {
    //       this.$set(this.inputCode[index], "code", this.inputCode[index].code.slice(1, 2).toUpperCase());
    //     } else {
    //       this.$set(this.inputCode[index], "code", this.inputCode[index].code.toUpperCase());
    //     }
    //     let dom = document.getElementById("testPaste" + nextIndex);
    //     if (dom) dom.focus();
    //   }
    //   let codeArr = this.inputCode.map((value) => {
    //     return value.code;
    //   });
    //   let code = codeArr.join('');
    //   console.log("code ", code);
    //   this.isSubmit = code.length !== 8;
    // },
    // handlePaste() {
    //   this.pasteFlag = this.inputCode.length === 8;
    // },
    // keyDown(index) {
    //   this.keyCode = event.keyCode;
    //   if (this.keyCode === 8 && (this.inputCode[index].code === undefined || this.inputCode[index].code === "")) {
    //     let dom = document.getElementById("testPaste" + (index - 1));
    //     if (dom) dom.focus();
    //     this.$set(this.inputCode[index], "code", "");
    //     return false;
    //   }
    // }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss">
.captcha_input_box {
  .el-input__inner {
    padding: 0;
    width: 74px;
    height: 74px;
    line-height: 74px;
    background: rgba(0, 0, 0, 0.01);
    margin-right: 12px;
    font-size: 60px;
    text-align: center;
    color: #000000;
    border: none;
  }
}
</style>
<style lang="scss" scoped>
.order-num {
  width: 200px;
  margin-right: 20px;
}
.btn-list {
  margin-bottom: 20px;
}
.search-date {
  width: 450px;
}
.invitation-code {
  width: 100%;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 27px;
    font-family: "PingFangSC-Regular, PingFang SC";
    font-weight: 400;
    color: #000000;
    line-height: 38px;
  }
  .captcha_input_wrapper {
    width: 100%;
    margin-top: 41px;
    margin-bottom: 31px;
  }
  .captcha_input_box {
    width: 74px;
    height: 74px;
    line-height: 74px;
    background: rgba(0, 0, 0, 0.08);
    margin-right: 12px;
    font-size: 60px;
    text-align: center;
    color: #000000;
    border: none;
  }
  .btn-submit {
    width: 216px;
    font-size: 22px;
    font-family: "PingFangSC-Regular, PingFang SC";
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }
  .text-error {
    margin-top: 20px;
    color: #ff0101;
  }
}
</style>
